<div class="main-content">
  <!-- Breadcrumbs Start -->
  <div class="rs-breadcrumbs sec-color">
    <div class="breadcrumbs-image">
        <img src="assets/images/breadcrumbs/about.jpg" class="bg-img" alt="Breadcrumbs Image">
        <div class="breadcrumbs-inner">
            <div class="container">
                <div class="breadcrumbs-text">
                    <h1 class="breadcrumbs-title">Products</h1>
                    <!-- <ul class="breadcrumbs-subtitle">
                        <li><a routerLink='/home'>Home</a></li>
                        <li>Products</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Breadcrumbs End -->

<div class="container mt-3">
  <div class="card m-4 card-bg">
    <div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12">
        <div class="text-center">
            <div class="rs-title">
                <h2 class="product-title">JUJUBE - The Great India Market</h2>
            </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12">
        <div class="text-center">
          <img style="width: 22%;" src="assets/images/jujube.png" alt="jujube logo">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12">
        <div class="rs-our-practice p-3">
            <div class="rs-title white-color">
                <h2 class="title"><span class="">OVERVIEW</span></h2>
                <p>{{overview}}</p>
            </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-12 col-sm-12">
        <div class="rs-our-practice p-3">
          <div class="rs-title white-color">
            <h2 class="title"><span class="">TECHNOLOGY FOR INNOVATORS</span></h2>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration=".5s" data-wow-delay="500ms">
          <div class="rs-our-practice p-3">
            <div class="content-details ">
                <div class="top-bar">
                    
                    <h4>JUJUBE - The Great India Market</h4>
                </div>
                <p class="des">{{reactiveDocumentContent}}</p>
                <!-- <a class="readon" href="client.html#resgi">Read More</a> -->
            </div>
          </div>  
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration=".5s" data-wow-delay="500ms">
            <div class="rs-our-practice p-3">
              <div class="content-details ">
                  <div class="top-bar">
                      
                      <h4>EMPOWRING BUSINESSES</h4>
                  </div>
                  <p class="des">{{centralizationContent}}</p>
                  <!-- <a class="readon" href="client.html#resgi">Read More</a> -->
              </div>
            </div>  
          </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 wow fadeInUp" data-wow-duration=".5s" data-wow-delay="500ms">
        <div class="rs-our-practice p-3">
            <div class="content-details ">
                <div class="top-bar">
                    
                    <h4>DECENTRALIZED ECONOMY</h4>
                </div>
                <p class="des">{{fasterProcessingContent}}</p>
                <!-- <a class="readon" href="client.html#resgi">Read More</a> -->
            </div>
          </div> 
      </div>
    </div>
  </div>
</div>
</div>