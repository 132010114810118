<div class="jumbotron ">
  <div class="mt-10">
    <div class="row">
      <div class="col-12">
        <div class="text-center rs-title">
          <h2 class="title-color">Our Clients</h2>
        </div>
      </div>
    </div>
    <div class="row p-20">
      <div class="col-md-4 col-lg-4 col-sm-12 text-center">
        <div class="swing-rodeo text-center">
            <img src="../../assets/images/rodeo logo.png" class="rodeo-img" alt="rodeo">
        </div>
      </div>
      <div class="col-md-8 col-lg-8 col-sm-12">
        <h4 class="client-content">Rodeo Business Process Management helps to automate and integrate manual processes and key systems seamlessly across organizations. Rodeo is a Java based application, a portable, robust and adaptive tool virtually integrates to industry leading technologies and its trends.It exposes its key capabilities as services that can be accessed through REST and SOAP based web services. Rodeo analytics helps business organizations to save costs, effective and fast decision making, Offer new products or services to their customers.</h4>
      </div>
    </div>
    <hr>
    <div class="row p-20 ml-4">
      <div class="col-md-8 col-lg-8 col-sm-12">
        <h4 class="client-content">With deep public safety experience, Kokomo24/7 Â® is an AI-driven, cloud-based safety platform that helps ensure safe learning environments. Kokomoâ€™s School Safety Cloud consists of easy-to-deploy, intuitive safety solutions designed to reduce bullying, suicide and other disruptive events in K12 & Higher Ed. Three components comprise the Kokomo Safety Cloud:</h4>
        <ul>
           <li>
              <h4 class="bullet-content">Incident Case Management with AI predictive analytics for bullying, suicide and other disruptive incidents</h4> 
           </li> 
           <li>
              <h4 class="bullet-content">Anonymous Reporting and Management for Community Citizens</h4> 
           </li>
           <li>
              <h4 class="bullet-content">Emergency Operation Center integration for real-time incident response and management</h4> 
           </li>
        </ul>
      </div>
      <div class="col-md-4 col-lg-4 col-sm-12 text-center">
        <div class="swing">
            <img src="../../assets/images/kokomo.png" class="" alt="kokomo 24/7">
        </div>  
        <!-- <img src="../../assets/images/kokomo.png" class="" alt=""> -->
      </div>
    </div>
    <hr>
    <div class="row mt-3">
        <div class="col-12">
          <div class="text-center rs-title">
            <h2 class="title-color">Solution Partner</h2>
          </div>
        </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="text-center">
           <img src="../../assets/images/Adobe_Solution_Partner_badge_Bronze.png" class="adobe-img" alt="adobe">
        </div>
      </div>
    </div>
  </div>
</div>