import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private http: HttpClient,
    private baseService: BaseService
  ) { }

  sendData(formData){
    console.log(formData);
    return this.http.post(this.baseService.baseUrl+"contactus/saveAll", formData);
  }
  sendEmail(email, name){
    return this.http.get(this.baseService.baseUrl+"contactus/contactUsEmail?email="+email+"&name="+name);
  }
}
