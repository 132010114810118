import { Component, OnInit } from '@angular/core';
declare var $;
@Component({
  selector: 'app-who-we-are',
  templateUrl: './who-we-are.component.html',
  styleUrls: ['./who-we-are.component.css']
})
export class WhoWeAreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('.owl-carousel').owlCarousel({
      loop:true,
      margin:10,
      dots:true,
      nav:false,
      mouseDrag:false,
      autoplay:true,
      animateOut: 'slideOutUp',
      responsive:{
          0:{
              items:1
          },
          600:{
              items:1
          },
          1000:{
              items:1
          }
      }
  });
  }

}
