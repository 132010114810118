<!-- Main content Start -->
<div class="main-content">
    <!-- Slider Start -->
    <div id="rs-slider" class="rs-slider rs-slider-three">
        <div class="bend niceties">
            <div id="nivoSlider" class="slides">
                <img src="../assets/images/slider/home3_2.jpg" alt="" title="#slide-1" />
                <img src="../assets/images/slider/home3_1.jpg" alt="" title="#slide-2" />
            </div>
            <!-- Slide 1 -->
            <div id="slide-1" class="slider-direction">
                <div class="display-table">
                    <div class="display-table-cell">
                        <div class="container text-center">
                            <div class="slider-inner">
                                <div class="slider-des">
                                    <h1 class="sl-title">JUJUBE - The Great India Market<span>
                                      India's 1st e-commerce platform empowering common man's busniess
                                     </span><span>email us: support@pradiota.com</span>
                                    </h1>
                                </div>
                                <div class="slider-bottom">
                                    <ul>
                                        <li><a  class="readon" routerLink="/products" style="cursor: pointer;">Know More</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Slide 2 -->
            <div id="slide-2" class="slider-direction">
                <div class="display-table">
                    <div class="display-table-cell">
                        <div class="container text-center">
                            <div class="slider-inner" >
                                <div class="slider-des">
                                  <a  >  <h1 class="sl-title">SPREADING THE POWER OF INNOVATION<span>THAT TRANSFORM THE GLOBAL ECONOMY</span><span>PARTNER WITH PRADIOTA JUJUBE</span></h1></a>

                                </div>
                                <div class="slider-bottom">
                                    <ul>
                                        <li><a href="http://www.pradiota.com/#/contact-us" class="readon" style="cursor: pointer;">Know more about JUJUBE</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Slider End -->

    <div class="section-bg">
        <!-- Services Start -->
        <section id="rs-services" class="rs-services-4">
            <div class="container-fluid pl-0 pr-0">
                <div class="row rs-vertical-middle">
                    <div class="col-lg-12">
                        <div class="service-inner services-list">
                            <!-- Services One-->
                            <div class="service-box service-box1">
                                <div class="icon-part">
                                    <i class="glyph-icon flaticon-work"></i>
                                    <a href="#">
                                        <h3>Our Vision</h3>
                                    </a>
                                </div>
                                <div class="text">
                                    <p>{{vision}}</p>
                                </div>
                            </div>

                            <!-- Services Two-->
                            <div class="service-box service-box2">
                                <div class="icon-part">
                                    <i class="glyph-icon flaticon-house"></i>
                                    <a href="#">
                                        <h3>Our Mission</h3>
                                    </a>
                                </div>
                                <div class="text">
                                    <p>{{mission}}</p>
                                </div>
                            </div>

                            <!-- Services Three-->
                            <div class="service-box service-box3">
                                <div class="icon-part">
                                    <i class="glyph-icon flaticon-team"></i>
                                    <a href="#">
                                        <h3>Our Objective</h3>
                                    </a>
                                </div>
                                <div class="text">
                                    <p>{{objective}}</p>
                                </div>
                            </div>

                            <!-- Services Four-->
                            <div class="service-box service-box4">
                                <div class="icon-part">
                                    <i class="glyph-icon flaticon-support"></i>
                                    <a href="#">
                                        <h3>Support</h3>
                                    </a>
                                </div>
                                <div class="text">
                                    <p>{{support}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 4th -->

            </div>
        </section>
        <!-- Services End -->

        <!-- About Lawyer Start -->
        <div id="rs-about-lawyer" class="rs-about-lawyer pt-100 pb-2">
            <div class="container">
                <div class="row rs-vertical-middle">
                    <div class="col-lg-6 mb-md-40 pr-30">
                        <img class="images-shadow" src="../assets/images/about/about3.jpg" alt="">
                    </div>
                    <div class="col-lg-6 pl-30">
                        <div class="rs-title">
                            <h2>Welcome to PRADIOTA TECHNOLOGIES</h2>
                        </div>
                        <h5 class="sub-tilte" >
                            {{highlightContent}}
                        </h5>
                        <p class="des">{{miniContent}}</p>
                        <a class="readon" routerLink="/who-we-are">Know More</a>
                        <div class="service-call mt-45">
                            <h4 style="
                            color: #8d6e63;
                        ">For Any Enquiry And Question Please Call us.</h4>
                            <ul>
                                <li><a href="tel:18003097199">18003097199</a></li>
                                <!-- <li>or</li>
                                <li><a href="tel:9848450233">+91-9848450233</a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- About Lawyer End -->



        <div id="rs-about-lawyer" class="rs-about-lawyer pt-100 pb-2">
            <div class="container">
                <div class="row rs-vertical-middle">
                    <div class="col-lg-6 pl-30 mb-5">
                        <div class="rs-title">
                            <h2>JUJUBE</h2>
                        </div>
                        <h5 class="sub-tilte" >
                           India's 1st e-commerce platform empowering common man's business
                        </h5>
                        <p class="des">Pradiota Technologies is envisioned and developing an unique, novel and sustainable platform for businesses in India to expand beyond their customer reach. Building a sustainable economy model that decentralizes economy, low operational costs, enables quality controls and thereby helping to deliver more authentic and customer centric products and services. Jujube will integrate seamlessly without any major transformations required to the existing systems across the nation. It is a direct marketing platform without a broker involvement.  Jujube is India’s ONLY & 1st ever e-ecommerce platform empowering common man’s business.</p>
                        <a class="readon" routerLink="/products">Know More</a>
                        
                    </div>

                    <div class="col-lg-6 mb-md-40 pr-30">
                        <!-- <img class="images-shadow" src="../assets/images/about/about3.jpg" alt=""> -->
                        <video class="images-shadow video" controls>
                            <source src="../../assets/jujube video.mp4" type="video/mp4">
                        </video>
                        <div class="playpause"></div>
                    </div>
                </div>
            </div>
        </div>



        <!-- Team Inner Section Start -->
        <div id="rs-team" class="rs-team-slider2 rs-team-slider pt-4 pb-4" style="
        background: #da6f17;
    ">
            <div class="rs-heading-style3 text-center">
                <!-- <h4 class="sub-title top-line primary">PRADIOTA TEAM</h4> -->
                <h2 class="title text-white">TEAM PRADIOTA</h2>
            </div>
            <div class="container">
                <div class="rs-carousel owl-carousel" data-loop="true" data-items="4" data-margin="20"
                    data-autoplay="true" data-autoplay-timeout="3000" data-smart-speed="1200" data-dots="false"
                    data-nav="true" data-nav-speed="false" data-mobile-device="1" data-mobile-device-nav="true"
                    data-mobile-device-dots="false" data-ipad-device="2" data-ipad-device-nav="true"
                    data-ipad-device-dots="false" data-ipad-device2="2" data-ipad-device-nav2="true"
                    data-ipad-device-dots2="false" data-md-device="4" data-md-device-nav="false"
                    data-md-device-dots="false">
                    <div class="team-item">
                        <div class="team-image">
                            <img [src]="ramesh.imagePath" alt="Ramesh">
                            <div class="team-content">
                                <div class="text-box">
                                    <!-- <a href="team-single.html"><i class="glyph-icon flaticon-eye"></i></a> -->
                                    <div class="team-social">
                                        <ul>
                                            <li><a href="https://www.linkedin.com/in/jujube-the-great-india-market-65526a233/"><i class="fa fa-linkedin"></i></a></li>
                                            <!-- <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                            <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li> -->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dark-bg">
                            <h4 class="person-name"><a >{{ramesh.name}}</a></h4>
                            <span class="designation">{{ramesh.role}}</span>
                        </div>
                    </div>
                    <div class="team-item">
                        <div class="team-image">
                            <img [src]="rajesh.imagePath" alt="Rajesh">
                            <div class="team-content">
                                <div class="text-box">
                                    <!-- <a href="team-single.html"><i class="glyph-icon flaticon-eye"></i></a> -->
                                    <div class="team-social">
                                        <ul>
                                            <li><a href="https://www.linkedin.com/company/pradiota-it-solutions/"><i class="fa fa-linkedin"></i></a></li>
                                            <!-- <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                            <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li> -->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dark-bg">
                            <h4 class="person-name"><a >{{rajesh.name}}</a></h4>
                            <span class="designation">{{rajesh.role}}</span>
                        </div>
                    </div>
                    <div class="team-item">
                        <div class="team-image">
                            <img [src]="vamshi.imagePath" alt="vamshi">
                            <div class="team-content">
                                <div class="text-box">
                                    <!-- <a href="team-single.html"><i class="glyph-icon flaticon-eye"></i></a> -->
                                    <div class="team-social">
                                        <ul>
                                            <li><a href="https://www.linkedin.com/company/pradiota-it-solutions/"><i class="fa fa-linkedin"></i></a></li>
                                            <!-- <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                            <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li> -->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dark-bg">
                            <h4 class="person-name"><a >{{vamshi.name}}</a></h4>
                            <span class="designation">{{vamshi.role}}</span>
                        </div>
                    </div>
                    <div class="team-item">
                        <div class="team-image">
                            <img [src]="manasa.imagePath" alt="Manasa">
                            <div class="team-content">
                                <div class="text-box">
                                    <!-- <a href="team-single.html"><i class="glyph-icon flaticon-eye"></i></a> -->
                                    <div class="team-social">
                                        <ul>
                                            <li><a href="https://www.linkedin.com/company/pradiota-it-solutions/"><i class="fa fa-linkedin"></i></a></li>
                                            <!-- <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                            <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li> -->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dark-bg">
                            <h4 class="person-name"><a >{{manasa.name}}</a></h4>
                            <span class="designation">{{manasa.role}}</span>
                        </div>
                    </div>
                    
                    <div class="team-item">
                        <div class="team-image">
                            <img [src]="mohan.imagePath" alt="Mohan">
                            <div class="team-content">
                                <div class="text-box">
                                    <!-- <a href="team-single.html"><i class="glyph-icon flaticon-eye"></i></a> -->
                                    <div class="team-social">
                                        <ul>
                                            <li><a href="https://www.linkedin.com/company/pradiota-it-solutions/"><i class="fa fa-linkedin"></i></a></li>
                                            <!-- <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                            <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                            <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li> -->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dark-bg">
                            <h4 class="person-name"><a >{{mohan.name}}</a></h4>
                            <span class="designation">{{mohan.role}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Team Inner Section End -->

        <!-- Partner Start -->
        <div id="rs-defult-partner" class="rs-defult-partner sec-color pt-100 pb-100">
            <div class="rs-heading-style3 text-center">
                <h2 class="title">CUSTOMERS AND PARTNERS</h2>
            </div>
            <div class="container">
                <div class="rs-carousel owl-carousel" data-loop="true" data-items="3" data-margin="10"
                    data-autoplay="true" data-autoplay-timeout="8000" data-smart-speed="2000" data-dots="false"
                    data-nav="true" data-nav-speed="false" data-mobile-device="1" data-mobile-device-nav="true"
                    data-mobile-device-dots="false" data-ipad-device="2" data-ipad-device-nav="true"
                    data-ipad-device-dots="false" data-ipad-device2="3" data-ipad-device-nav2="true"
                    data-ipad-device-dots2="false" data-md-device="3" data-md-device-nav="true"
                    data-md-device-dots="false">
                    <div class="partner-item">
                        <div class="rs-team-slider">
                          <div class="team-item">
                            <a ><img src="../../assets/images/resize rodeo.png"  alt="Partner Image"></a>
                            <!-- <div class="team-content">
                              <div class="row">
                                <div class="col-12">
                                  <div class="text-center">
                                    <button class="btns first" routerLink="/clients">Read More</button>
                                  </div>
                                </div>
                              </div>
                            </div> -->
                          </div>
                        </div>
                       
                    </div>
                    <div class="partner-item">
                      <div class="rs-team-slider">
                        <div class="team-item">
                            <a ><img src="../../assets/images/jujube.png" alt="JUJUBE"></a>
                           <!-- <div class="team-content">
                            <div class="row">
                                <div class="col-12">
                                  <div class="text-center">
                                    <button class="btns first" routerLink="/clients">Read More</button>
                                  </div>
                                </div>
                              </div>
                           </div>  -->
                        </div>
                      </div> 

                    </div>
                    <div class="partner-item">
                      <div class="rs-team-slider">
                        <div class="team-item">
                            <a ><img src="../../assets/images/tatadlt.png" alt="TATA DLT"></a>
                           <!-- <div class="team-content">
                            <div class="row">
                                <div class="col-12">
                                  <div class="text-center">
                                    <button class="btns first" routerLink="/clients">Read More</button>
                                  </div>
                                </div>
                              </div>
                           </div>  -->
                        </div>
                      </div>   

                    </div>
                    <div class="partner-item">
                      <div class="rs-team-slider">
                        <div class="team-item">
                            <a ><img src="../../assets/images/digimiles.png" alt="Digimiles"></a>
                           <!-- <div class="team-content">
                            <div class="row">
                                <div class="col-12">
                                  <div class="text-center">
                                    <button class="btns first" routerLink="/clients">Read More</button>
                                  </div>
                                </div>
                              </div>
                           </div>  -->
                        </div>
                      </div>   

                    </div>
                    <div class="partner-item">
                      <div class="rs-team-slider">
                        <div class="team-item">
                            <a ><img src="../../assets/images/dunzo.png" alt="Dunzo"></a>
                           <!-- <div class="team-content">
                            <div class="row">
                                <div class="col-12">
                                  <div class="text-center">
                                    <button class="btns first" routerLink="/clients">Read More</button>
                                  </div>
                                </div>
                              </div>
                           </div>  -->
                        </div>
                      </div> 

                    </div>
                    <div class="partner-item">
                      <div class="rs-team-slider">
                        <div class="team-item">
                            <a ><img src="../../assets/images/cloudphone.png" alt="cloudphone"></a>
                           <!-- <div class="team-content">
                            <div class="row">
                                <div class="col-12">
                                  <div class="text-center">
                                    <button class="btns first" routerLink="/clients">Read More</button>
                                  </div>
                                </div>
                              </div>
                           </div>  -->
                        </div>
                      </div>  
                      
                    </div>
                    <div class="partner-item">
                      <div class="rs-team-slider">
                        <div class="team-item">
                            <a ><img src="../../assets/images/razorpay.png" alt="Razorpay"></a>
                           <!-- <div class="team-content">
                            <div class="row">
                                <div class="col-12">
                                  <div class="text-center">
                                    <button class="btns first" routerLink="/clients">Read More</button>
                                  </div>
                                </div>
                              </div>
                           </div>  -->
                        </div>
                      </div> 
                      
                    </div>
                    <div class="partner-item">
                      <div class="rs-team-slider">
                        <div class="team-item">
                            <a ><img src="../../assets/images/paypal.png" alt="Paypal"></a>
                           <!-- <div class="team-content">
                            <div class="row">
                                <div class="col-12">
                                  <div class="text-center">
                                    <button class="btns first" routerLink="/clients">Read More</button>
                                  </div>
                                </div>
                              </div>
                           </div>  -->
                        </div>
                      </div>   

                    </div>
                    <div class="partner-item">
                      <div class="rs-team-slider">
                        <div class="team-item">
                            <a ><img src="../../assets/images/2.PNG" alt="Partner Image"></a>
                           <!-- <div class="team-content">
                            <div class="row">
                                <div class="col-12">
                                  <div class="text-center">
                                    <button class="btns first" routerLink="/clients">Read More</button>
                                  </div>
                                </div>
                              </div>
                           </div>  -->
                        </div>
                      </div>  
                        
                    </div>
                    <div class="partner-item">
                      <div class="rs-team-slider">
                        <div class="team-item">
                          <a ><img src="../../assets/images/3.png" alt="Partner Image"></a>
                          <!-- <div class="team-content">
                            <div class="row">
                                <div class="col-12">
                                  <div class="text-center">
                                    <button class="btns first" routerLink="/clients">Read More</button>
                                  </div>
                                </div>
                              </div>
                          </div> -->
                        </div>
                      </div>  
                        
                    </div>
                    <div class="partner-item">
                      <div class="rs-team-slider">
                        <div class="team-item">
                          <a ><img src="../../assets/images/4.PNG" alt="Partner Image"></a>
                          <!-- <div class="team-content">
                            <div class="row">
                                <div class="col-12">
                                  <div class="text-center">
                                    <button class="btns first" routerLink="/clients">Read More</button>
                                  </div>
                                </div>
                              </div>
                          </div> -->
                        </div>
                      </div>  
                        
                    </div>
                </div>
            </div>
        </div>
        <!-- Partner End -->


    </div>