import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  navLinks = [
    { title: "Home", path: "home", class: "nav-item" },
    { title: "Products", path: "products", class: "nav-item" },
    { title: "Who we are", path: "who-we-are", class: "nav-item" },
    { title: "Services", path: "services", class: "nav-item" },
    { title: "Careers", path: "careers", class: "nav-item" },
    { title: "Contact us", path: "contact-us", class: "nav-item" },
  ]
  constructor() { }

  ngOnInit(): void {
    var header = $('.menu-sticky');
    var win = $(window);

    win.on('scroll', function () {
      var scroll = win.scrollTop();
      if (scroll < 1) {
        header.removeClass("sticky");
      } else {
        header.addClass("sticky");
      }

      $("section").each(function () {
        var elementTop = $(this).offset().top - $('#rs-header').outerHeight();
        if (scroll >= elementTop) {
          $(this).addClass('loaded');
        }
      });

    });
  }

}
