<div class="container mt-2">
  <div class="card m-3" style="background: #023e60;">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12">
            <div class="text-center">
                <div class="body">
                    <div class="glow">
                        <span class="major">UNLEASH THE POWER OF ARTIFICIAL INTELLIGENCE</span>
                      </div>
                </div>
            </div>
          </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12 col-lg-12 col-sm-12">
        <div class="rs-our-practice p-3">
          <div class="rs-title white-color">
            <p>
                At Pradiota, we employee Artificial Intelligence methods to build a next generation problem
solving document framework whose overall intellectual ability is indistinguishable from that
of a human being.
            </p>
            <p class="mt-2">
                We also aim at solving the problems of traditional automation like manual code writing,
                Complex API text data creation, maintenance overhead and slow coverage of the
                automation backlog.
            </p>
            <h2 class="title"><span class="hidder">BOT DRIVEN TEMPLATE CREATION</span></h2>
            <p>Bot driven templates improve the user experience with features like reactive
                transformations, correlated validations and suitable for all kinds of scenarios. It supports
                data binding and has an automatic track of the form and its data.
            </p>
            <h2 class="title"><span class="hidder">ENSURING DATA ACCURACY</span></h2>
            <p>
                With so much data streaming from diverse sources, data compatibility becomes a potential
problem. However, we at Pradiota ensure that data quality and data accuracy of all formats
including complex formats is maintained in the process of moving data from a source to
destination by providing built-in tools which saves time and reduces possibility of human
error.
            </p>
            <p class="mt-2">
                The build-in tools we provide in our document framework are fast, flexible and are built to
                handle demanding data mapping needs. The built-in tools are capable of creating a
                complete data mapping workflow and also support various types of file formats.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>