import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProductsComponent } from './products/products.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { ServicesComponent } from './services/services.component';
import { SliderOneContentComponent } from './slider-one-content/slider-one-content.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ClientsComponent } from './clients/clients.component';
import { CareersComponent } from './careers/careers.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'products', component: ProductsComponent },
  { path: 'who-we-are', component: WhoWeAreComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'pradiota-AI', component: SliderOneContentComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'clients', component: ClientsComponent },
  { path: 'careers', component: CareersComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
