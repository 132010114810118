import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ProductsComponent } from './products/products.component';
import { NgwWowModule } from 'ngx-wow';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { ServicesComponent } from './services/services.component';
import { SliderOneContentComponent } from './slider-one-content/slider-one-content.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ClientsComponent } from './clients/clients.component';
import { CareersComponent } from './careers/careers.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DisclaimerModalComponent } from './disclaimer-modal/disclaimer-modal.component';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    ProductsComponent,
    WhoWeAreComponent,
    ServicesComponent,
    SliderOneContentComponent,
    ContactUsComponent,
    ClientsComponent,
    CareersComponent,
    DisclaimerModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgwWowModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    BrowserAnimationsModule
  ],
  providers: [ {provide: LocationStrategy, useClass: HashLocationStrategy} ],
  //entryComponents: [ DisclaimerModalComponent ],
  bootstrap: [AppComponent]
})
export class AppModule { }
