<span class="slider-animation">
    <div class="owl-carousel owl-theme">
        <div class="item">
            <img src="https://cdn.pixabay.com/photo/2017/10/24/10/30/business-2884023_960_720.jpg" alt="images not found">
            <div class="cover">
                <div class="container">
                    <div class="header-content">
                        <div class="line"></div>
                        <h1>JUJUBE - The Great India Market</h1>
                        <h2>India's 1st e-commerce platform empowering common man's business</h2>
                        <h2>JUJUBE is LIVE in Google Playstore</h2>
                         
                    </div>
                </div>
             </div>
        </div>                    
        <div class="item">
            <img src="https://cdn.pixabay.com/photo/2016/03/09/09/22/workplace-1245776_960_720.jpg" alt="images not found">
            <div class="cover">
                <div class="container">
                    <div class="header-content">
                        <div class="line animated bounceInLeft"></div>
                        <h1>JUJUBE - The Great India Market</h1>
                        <h2>Opens self-service registrations for businesses</h2> 
                        <h2>Integrates automatic vendor payments</h2>   
                        <h2>Transparent and direct marketing platform for vendors</h2>     

                        <!--<h4>CENTRALIZATION</h4> --> 
                    </div>
                </div>
             </div>
        </div>                
        <div class="item">
            <img src="https://cdn.pixabay.com/photo/2017/05/04/16/37/meeting-2284501_960_720.jpg" alt="images not found">
            <div class="cover">
                <div class="container">
                    <div class="header-content">
                        <div class="line animated bounceInLeft"></div>
                        <h1>JUJUBE - The Great India Market</h1>
                        <h2>Empowers common man's business</h2>
                        <h2>Supports regional languages</h2>
                        <h2>World class e-commerce platform for common man's business</h2>
                     
                    </div>
                </div>
             </div>
        </div>
    </div>
</span>

<div id="rs-about-lawyer" class="rs-about-lawyer pt-50 pb-100">
    <div class="container">
        <div class="about-inner">
            <div class="row rs-vertical-middle">
                <div class="col-lg-12">
                    <div class="about-style2 pt-50 pl-50 pb-50 pr-50">
                        <div class="rs-heading-style3">
                            <h2>PRADIOTA TECHNOLOGIES</h2>
                        </div>
                        <p class="des">We believe in the value of relationships. We view every client
                            relationship like a partnership, and
                            truly believe that our success is a result of your success</p>
                        <p class="des">We are committed to providing close, personal attention to our clients.
                            We take pride
                            in giving you
                            the assurance that the assistance you receive comes from years of advanced training,
                            technical
                            experience and financial acumen. Our continual investment of time and resources in
                            professional
                            continuing education, advanced computer technology and extensive business
                            relationships is
                            indicative of our commitment to excellence. </p>
                        <P class="des">
                           <b class="sub-title">WE ENVISION IT WE MAKE IT</b>
                        </P>
                        <p class="des">
                            Pradiota IT Solutions Pvt Ltd was founded in 2015 with an ordinary group of people and an extraordinary vision.
                        </p>
                        <p class="des">
                            We are an ideal combination of Innovation, Commitment, Trust and Never say No Attitude. Our Organization is always ready for taking up new challenges and we offer any technology product that our clients request along with all the necessary services.
                        </p>
                        <p class="des">
                            At Pradiota, we create highest and grandest vision possible because we become what we believe. This belief has driven us in providing quality assured services by concentrating on nano aspects of every problem to the clients across the globe.
                        </p>
                        <P class="des">
                            <b class="sub-title">HAPPIER THE EMPLOYEE HIGHER THE PRODUCTIVITY</b>
                        </P>
                        <p class="des">
                            Employee satisfaction is highly prioritized here and we strive to create a happier environment for them personally and professionally by looking after them.
                        </p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>