<div>
    <div class="modal-header">
        <h4 class="modal-title">Disclaimer</h4>
        <div class="ml-auto">
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body">
      <h5>Virginia Non-Disclosure Agreement: What Is It?</h5>
      <div class="row mt-2">
        <div class="col-12">
          <p>A Virginia non-disclosure agreement is a legal document that is used to protect business and trade secrets. Since Virginia non-disclosure agreements may be legally binding, it is important that your document be written the right way. It must be specific; this includes listing to whom employees and former employees can give information to about your business. That information must be specifically defined in the Virginia non-disclosure agreement.</p>
        </div>
      </div>
      <div class="mt-2">
        <h5>Virginia State Laws</h5>
        <div class="row mt-2">
          <div class="col-12">
            <p>Virginia adopted the Uniform Trade Secrets Act, <span class="text-primary">Va. Code Ann. §§ 59.1-336 through 59.1-343</span>. The Uniform Trade Secrets Act governs non-disclosure agreements and the information that NDAs are meant to protect.</p>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <h5>Definition of “Trade Secrets”</h5>
        <div class="row mt-2">
          <div class="col-12">
            <p>With a Virginia non-disclosure agreement, a business can protect certain information referred to as a “trade secret.” Of course, that information must be sufficiently documented within the NDA. Yet, before this is done, it is important that a business understand the sort of information that is considered <span class="text-primary">a “trade secret” as defined</span> in the Virginia Uniform Trade Secrets Act. The law defines a “trade secret” as information that includes but is not limited to a formula, a pattern, a compilation of data, a program, a device, a method, a technique, or a process with its own actual or potential financial value because it isn’t something that is easy for the public or another business to figure out during their normal actions. For any information that a business wants to consider as a trade secret, the business must take reasonable steps to keep that information private.</p>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <h5>Laws</h5>
        <div class="row mt-2">
          <div class="col-12">
            <p>The Virginia Uniform Trade Secrets Act also explains how businesses can <span class="text-primary">preserve the secrecy</span> of the information they wish to protect as well as other valuable information.</p>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="row">
          <div class="col-12">
            <p>Virginia businesses can get additional protection for their secrets by using this document together with a <span class="text-primary">Virginia noncompete agreement</span>.</p>
          </div>
        </div>
      </div>
    </div>
</div>