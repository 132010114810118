<!-- Preloader area start here -->
<!-- <div id="loading" class="image-preloader">
    <div class="loader"><img src="../../assets/images/logo.png" alt=""></div>
</div> -->
<!--End preloader here -->

<!--Full width header Start-->
<div class="full-width-header">
    <!-- Toolbar Start -->
    <!-- <div class="toolbar-area hiser">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="toolbar-contact naroot">
                        <ul>
                            <li>IND - <i class="fa fa-phone"></i><a href="tel:+9533673777">18002127436</a></li>
                            <li><i class="fa fa-envelope-o"></i><a
                                    href="mailto:support@pradiota.com">support@pradiota.com</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- Toolbar End -->
    <!--Header Start-->
    <header id="rs-header" class="rs-header">
        <!-- Menu Start -->
        <div class="menu-area menu-sticky">
            <div class="container">
                <div class="row">
                    <div class="col-lg-2 col-md-12">
                        <div class="logo-area">
                            <a routerLink="/home"><img src="../../assets/images/Logo_gray.PNG" alt="logo"></a>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12">
                        <div class="main-menu">
                            <a class="rs-menu-toggle"><i class="fa fa-bars"></i>Menu</a>
                            <nav class="rs-menu">
                                <ul class="nav-menu text-center" >

                                <span *ngFor="let option of navLinks" style="text-align: left;">
                                    <li class="" ><a  [routerLink]="option.path"><span [routerLinkActive]="option.class">{{option.title}}</span> </a></li>
                                </span>
                                    
                            
                                <!-- Mega Menu Start -->
                                    <!-- <li class="rs-mega-menu mega-rs menu-item-has-children">
                                        <a href="#">Client
                                            Services</a>
                                        <ul class="mega-menu">
                                            <li class="mega-menu-container">
                                                <div class="mega-menu-innner">

                                                    <div class="single-megamenu">
                                                        <ul class="sub-menu">
                                                            <li><a href="client.html#resgi">Registrations</a></li>
                                                            <li><a href="client.html#resgi1">Accounting Services</a>
                                                            </li>
                                                            <li><a href="client.html#resgi2">Audits, Reviews and
                                                                    Compilations</a></li>
                                                            <li><a href="client.html#resgi2">Audit Services</a></li>
                                                            <li><a href="client.html#resgi4">Cash Flow &amp;
                                                                    Budgeting
                                                                    Analysis</a></li>
                                                        </ul>
                                                    </div>

                                                    <div class="single-megamenu">
                                                        <ul class="sub-menu">
                                                            <li><a href="client.html#resgi5">Forensic Accounting</a>
                                                            </li>
                                                            <li><a href="client.html#resgi6">Tax Services</a> </li>
                                                            <li><a href="client.html#resgi7">Business Valuations</a>
                                                            </li>
                                                            <li><a href="client.html#resgi8">Purchase and Sale of a
                                                                    Business</a></li>
                                                            <li><a href="client.html#resgi9">Payroll Related
                                                                    Service</a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div class="single-megamenu">
                                                        <ul class="sub-menu">
                                                            <li><a href="client.html#resgi10">Secretarial
                                                                    Services</a></li>
                                                            <li><a href="client.html">ISO Certification Services</a>
                                                            </li>
                                                            <li><a href="client.html">Project Financing</a></li>
                                                            <li><a href="client.html">Home loans and Personal Loan
                                                                    Processing</a></li>
                                                            <li><a href="client.html#resgi11">Other Services</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul> 
                                    </li> -->
                                    <!--Mega Menu End -->

                                </ul> <!-- //.nav-menu -->
                            </nav>
                        </div> <!-- //.main-menu -->
                        
                    </div>
                    <div class="col-lg-3 col-md-12" style="padding-right: 7px;">
                        <i class="fa fa-phone"></i><a href="tel:+18003097199" class="contact">18003097199</a>
                        <i class="fa fa-envelope-o" style="margin-left: 4px;"></i><a
                        href="mailto:support@pradiota.com" class="contact ml-1">support@pradiota.com</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Menu End -->
    </header>
    <!--Header End-->
</div>
<!--Full width header End-->