import { Component, OnInit } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  overview: string = "Pradiota Technologies is envisioned and developing an unique, novel and sustainable platform for businesses in India to expand beyond their customer reach. Building a sustainable economy model that decentralizes economy, low operational costs, enables quality controls and thereby helping to deliver more authentic and customer centric products and services. Jujube will integrate seamlessly without any major transformations required to the existing systems across the nation. It is a direct marketing platform without a broker involvement.  Jujube is India’s ONLY & 1st e-ecommerce platform empowering common man’s business.";
  reactiveDocumentContent: string = "JUJUBE is a sole-less e-commerce platform seamlessly integrates existing business models & systems in India";
  centralizationContent: string = "JUJUBE enabled self-service registrations for businesses to list on the platform. Empowers business users to manage product catalogs, choose their delivery and transport partners and set discounts to their products etc…";
  fasterProcessingContent: string = "JUJUBE is building a sustainable direct marketing platform and kicking out broker system and thereby by enabling individual businesses to take advantage of the digital era and expand their businesses to optimum levels. Encourages to gain maximum profits and at the same time providing a value to customers penny.";
  constructor(private wowService: NgwWowService) { }

  ngOnInit(): void {
    this.wowService.init();
  }

}
