<!-- <div class="service-page">
    <div id="bodycard">
        <div id="card">
            <div id="frontcard">Front</div>
            <div id="backcard">Back</div>
        </div>
    </div>
</div> -->

<div class="main-content">
    <div class="rs-breadcrumbs sec-color">
        <div class="breadcrumbs-image">
            <img src="assets/images/breadcrumbs/about.jpg" class="bg-img" alt="Breadcrumbs Image">
            <div class="breadcrumbs-inner">
                <div class="container">
                    <div class="breadcrumbs-text">
                        <h1 class="breadcrumbs-title">Services</h1>
                        <!-- <ul class="breadcrumbs-subtitle">
                            <li><a routerLink='/home'>Home</a></li>
                            <li>Services</li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="jumbotron">
      <div class=" m-4" >
        <div class="row m-3">
          
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
            <div id="bodycard" class="p-2">
                <div id="card">
                    <div id="frontcard"><h2 class="text-white">Mobile Development</h2></div>
                    <div id="backcard">
                        <div class="row padding">
                            <ul class="liststyle">
                                <li class="ml-2  liststyle"><i class="fa fa-mobile mr-3"></i><span class="service-list-items">Hybrid app development</span> </li>
                                <li class="ml-2 liststyle"><i class="fa fa-mobile mr-3"></i><span class="service-list-items">Android app development</span></li>
                                <li class="ml-2 liststyle"><i class="fa fa-mobile mr-3"></i><span class="service-list-items">Iphone app development</span></li>
                          </ul>
                        </div>
                    </div>
                </div>
            </div>
          </div>

          <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
            <div id="bodycard" class="p-2">
                <div id="card">
                    <div id="frontcard"><h2 class="text-white">Web Development</h2> </div>
                    <div id="backcard">
                        <div class="row padding">
                            <ul class="liststyle">
                                <li class="ml-2  liststyle"><i class="fa fa-laptop mr-3"></i><span class="service-list-items">Frontend development</span> </li>
                                <li class="ml-2 liststyle"><i class="fa fa-laptop mr-3"></i><span class="service-list-items">Web development Java</span> </li>
                                <li class="ml-2 liststyle"><i class="fa fa-laptop mr-3"></i><span class="service-list-items">Web development .Net</span> </li>
                                <li class="ml-2 liststyle" ><i class="fa fa-laptop mr-3"></i><span class="service-list-items">Content Managment AEM</span> </li>
                          </ul>
                        </div>
                    </div>
                </div>
            </div>
          </div>

          <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
            <div id="bodycard" class="p-2">
                <div id="card">
                    <div id="frontcard"><h2 class="text-white">Quality Assurance</h2></div>
                    <div id="backcard">
                        <div class="row padding">
                            <ul class="liststyle">
                                <li class="ml-2  liststyle"><i class="fa fa-laptop mr-3"></i><span class="service-list-items">Testing Applications</span> </li>
                                <li class="ml-2 liststyle"><i class="fa fa-laptop mr-3"></i><span class="service-list-items">Manual Testing</span></li>
                                <li class="ml-2 liststyle"><i class="fa fa-laptop mr-3" style="margin-left: 0px;"></i><span class="service-list-items">Automation Testing</span> <span class="service-list-items" style="display: flex; padding-left: 30px;">Selenium Java</span></li>
                                <li class="ml-2 liststyle" ><i class="fa fa-laptop mr-3" style="margin-left: 1px;"></i><span class="service-list-items">Automation Testing</span> <span class="service-list-items" style="display: flex; padding-left: 30px;">Cucumber</span></li>
                          </ul>
                        </div>
                    </div>
                </div>
            </div>
          </div>

          <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
            <div id="bodycard" class="p-2">
                <div id="card">
                    <div id="frontcard"><h2 class="text-white">Support</h2></div>
                    <div id="backcard"><div class="row padding">
                        <ul class="liststyle">
                            <li class="ml-2  liststyle"><i class="fa fa-laptop mr-3"></i><span class="service-list-items">Web Applications Support</span></li>
                            <li class="ml-2 liststyle"><i class="fa fa-laptop mr-3"></i><span class="service-list-items">Mobile Application Support</span></li>
                            <li class="ml-2 liststyle"><i class="fa fa-laptop mr-3"></i><span class="service-list-items">Web development.Net</span></li>
                            <li class="ml-2 liststyle" ><i class="fa fa-laptop mr-3"></i><span class="service-list-items">L1, L2 and L3  Support</span></li>
                      </ul>
                    </div></div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>