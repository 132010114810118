import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ContactService } from '../contact.service';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DisclaimerModalComponent } from '../disclaimer-modal/disclaimer-modal.component';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  myForm: FormGroup;
  submitted = false;
  theCheckbox = false;

  @ViewChild('formDirective') private formDirective: NgForm;
  constructor(
    private _formBuilder: FormBuilder,
    private route: Router,
    private http: HttpClient,
    private modalService: NgbModal,
    private contactService: ContactService
  ) {
    this.myForm = this._formBuilder.group({
      emailId: ['', [Validators.required, Validators.email]],
      name: ['', [ Validators.required,  Validators.pattern('^[a-zA-Z \-\']+')] ],
      phoneNo: ['', [Validators.required,
      // Validators.pattern("^((\\0){3})?[0-9]{7}$"),
      // Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/),
      Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im),
      Validators.minLength(10), Validators.maxLength(10 | 12 | 13 | 14)]],
      message: ['', Validators.required],
      createdAt: [null],
      modifiedAt: [null]
    })
  }

  ngOnInit(): void {
  }

  get f() { return this.myForm.controls; }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  toggleVisibility(e){
    this.theCheckbox = e.target.checked;
  }

  getDisclaimer(){
    this.modalService.open( DisclaimerModalComponent, { size: 'lg', windowClass: "modal-xl", backdrop: 'static', keyboard: false });
  }



  submit() {
    this.submitted = true;
    
    
    if (this.myForm.valid && this.theCheckbox) {
      this.contactService.sendData(this.myForm.value).subscribe(result => {
       

        if (result['statusCode'] == 200) {
          Swal.fire({
            icon: 'success',
            title: 'Thank you for submitting the request',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          });
         this.contactService.sendEmail(this.myForm.value.emailId, this.myForm.value.name).subscribe(result => {
           //console.log(result);
         })
         this.submitted = false; 
        this.formDirective.reset({});
       // this.myForm.setErrors(null);
        }
        if (result['statusCode'] == 400) {
          Swal.fire({
            icon: 'error',
            title: result['error'].errorMessage,
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          })
        }

      })
    }
    else {
      if(this.theCheckbox){
        Swal.fire({
          icon: 'error',
          title: 'Please Provide Correct Details',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        });
      }
      if(this.theCheckbox == false){
        Swal.fire({
          icon: 'error',
          title: 'Please agree to the privacy policy',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        })
      }
      
    }
    // Swal.fire({
    //   icon: 'success',
    //   title: 'Thank you for your valuable message',
    //   showClass: {
    //     popup: 'animate__animated animate__fadeInDown'
    //   },
    //   hideClass: {
    //     popup: 'animate__animated animate__fadeOutUp'
    //   }
    // })

  }
}
