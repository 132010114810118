<div class="jumbotron">
    <div class="col-12">
        <div class="text-center">
            <img class="flextwo"
                src="https://res.cloudinary.com/huda-tariq-design/image/upload/v1484581124/iStock-test_i4rt8j.jpg">
            <div class="blfovrly overlaytwo">
                <h2 class="blfo">Beliefs</h2>
                <div class="row">
                    <div class="col-12">
                        <h3 class="ml-2 text" style="text-align: left;">Everyday at Pradiota, our core beliefs guide us
                            towards achieving our mission as a team.</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="careercontent m-4">
   <h2 class="culture">Our Culture</h2> 
   <div class="row">
     <div class="col-md-12 col-lg-12 col-sm-12">
        <p class="culture-content">We always believe employee satisfaction lead to customer and company success. Our team is a cutting edge technology and we have the need to give the best to our clients. We love to hire people who are passionate in technology, love to work in the challenging environment and wanted to see great heights in careers. If you want see the difference, join with us and let's put our hands together so we can give best.</p>
     </div>
   </div>
</div>

<div class="container text-center">
  <div class="row">
    <div class="col-12">
      <div class="text-center">
        <div class="rs-title">
            <h2 style="color: #da6f17;">Benefits</h2>
        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-md-4 col-lg-4 col-sm-6">
      <span ><i class="fa fa-home icons"></i></span>
      <h5 class="benifits">Work From Home</h5>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-6">
        <span ></span><i class="fas fa-user-clock icons"></i>
        <h5 class="benifits">Flexible Timings</h5>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-6">
        <i class="fas fa-chalkboard-teacher icons"></i>
        <h5 class="benifits">Training & Workshops</h5>
    </div> 
  </div>

  <div class="row">
    <div class="col-md-4 col-lg-4 col-sm-6">
      <i class="fas fa-pizza-slice icons"></i>
      <h5 class="benifits">Snack Bar</h5>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-6">
        <i class="fas fa-medal icons"></i>
        <h5 class="benifits">Compensation & Recognition</h5>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-6">
        <i class="fas fa-glass-cheers icons"></i>
        <h5 class="benifits">Team outings</h5>
    </div> 
  </div>

  <div class="row">
    <div class="col-md-4 col-lg-4 col-sm-6">
      <i class="fas fa-briefcase-medical icons"></i>
      <h5 class="benifits">Health Care Insurance</h5>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-6">
        <i class="fas fa-money-bill-wave icons"></i>
        <h5 class="benifits">Bonus</h5>
    </div>
    <div class="col-md-4 col-lg-4 col-sm-6">
        <i class="fas fa-taxi icons"></i>
        <h5 class="benifits">Night Cabs</h5>
    </div> 
  </div>


  <div class="row mt-2">
    <div class="col-12">
      <div class="text-center">
        <div class="rs-title">
            <h2 style="color: #da6f17;">Current Opportunities</h2>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 col-lg-6 col-sm-12 text-center">
      <div class="card">
        <div class="card-content">
          <h5 class=""><span>Python Developer with Data Analytics</span></h5>
            <p class=""><i class="fas fa-user-tie"></i>Experience: 3 to 7 years</p>
            <p class=""><i class="fas fa-map-marker-alt mr-2"></i>Location: Hyderabad</p>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6 col-sm-12 text-center">
      <div class="card">
          <div class="card-content">
              <h5 class=""><span>Frontend Developer with Angular Framework</span></h5>
                <p class=""><i class="fas fa-user-tie"></i>Experience: 3 to 7 years</p>
                <p class=""><i class="fas fa-map-marker-alt mr-2"></i>Location: Hyderabad</p>
            </div>
       </div>
    </div>
  </div>
  <div class="row mt-2">
      <div class="col-md-6 col-lg-6 col-sm-12 text-center">
        <div class="card">
          <div class="card-content">
            <h5 class=""><span>Marketing Analytics</span></h5>
              <p class=""><i class="fas fa-user-tie"></i>Experience: 3 to 7 years</p>
              <p class=""><i class="fas fa-map-marker-alt mr-2"></i>Location: Hyderabad</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 text-center">
        <div class="card">
            <div class="card-content">
                <h5 class=""><span>Technical Manager</span></h5>
                  <p class=""><i class="fas fa-user-tie"></i>Experience: 10 to 15 years</p>
                  <p class=""><i class="fas fa-map-marker-alt mr-2"></i>Location: Hyderabad</p>
              </div>
         </div>
      </div>
    </div>
    <div class="row mt-3" >
      <div class="col-md-12 col-lg-12 col-sm-12">
        <div class="text-center">
          <h3 class="resume" >
            Please drop your updated resume to <span class="redtext"><b>hr@pradiota.com</b></span>
            </h3>
        </div>
      </div>          
      </div>

</div>

<!-- <section id="what-we-do">
    <div class="services-section text-center">
        <div class="container">
            <div class="site-title">
                <h2 class="benifitsheading">Benifits</h2>
            </div>
            <div class="services-three-columns">
                <div class="col-md-4 services-column">
                    <span class="icons"><i class="fas fa-home"></i></span>
                    <h5 class="benifits">Work From Home</h5>
                </div>
                <div class="col-md-4 services-column">
                    <span class="icons"><i class="fas fa-clock"></i></span>
                    <h5 class="benifits">Flexible Timings</h5>
                </div>
                <div class="col-md-4 services-column">
                        <span class="icons"><i class="glyphicons glyphicons-play-circle"></i></span>
                    <h5 class="benifits">Traning & Workshops</h5>
                </div> 
            </div>
            <div class="services-three-columns">
                    <div class="col-md-4 services-column">
                        <span class="icons"><i class="glyphicons glyphicons-glass"></i></span>
                        <h5 class="benifits">Snack Bar</h5>
                    </div>
                    <div class="col-md-4 services-column">
                        <span class="icons"><i class="glyphicons glyphicons-check"></i></span>
                        <h5 class="benifits">Compensation & Recognization</h5>
                    </div>
                    <div class="col-md-4 services-column">
                        <span class="icons"><i class="glyphicons glyphicons-cutlery"></i></span>
                        <h5 class="benifits">Team Outings</h5>
                    </div> 
                </div>
                <div class="services-three-columns">
                        <div class="col-md-4 services-column">
                            <span class="icons"><i class="glyphicons glyphicons-plus-sign"></i></span>
                            <h5 class="benifits">Helath Care Insurance</h5>
                        </div>
                        <div class="col-md-4 services-column">
                            <span class="icons"><i class="glyphicons glyphicons-star"></i></span>
                            <h5 class="benifits">Bonus</h5>
                        </div>
                        <div class="col-md-4 services-column">
                            <span class="icons"><i class="glyphicons glyphicons-map-marker"></i></span>
                            <h5 class="benifits">Night Cabs</h5>
                        </div> 
                    </div>
        </div>
    </div>
  
    </section> -->
