<div class="contactus-page">
    <div class="background">
        <div class="container">
          <div class="screen">
            <div class="screen-header">
              <!-- <div class="screen-header-left">
                <div class="screen-header-button close"></div>
                <div class="screen-header-button maximize"></div>
                <div class="screen-header-button minimize"></div>
              </div>
              <div class="screen-header-right">
                <div class="screen-header-ellipsis"></div>
                <div class="screen-header-ellipsis"></div>
                <div class="screen-header-ellipsis"></div>
              </div> -->
            </div>
            <div class="screen-body">
              <div class="screen-body-item left">
                <div class="app-title">
                  <span>CONTACT US</span>
                 <!-- <span></span>-->
                </div>
                <div class="row mt-4 text-white">
                  <div class="col-12">
                   <p>PRADIOTA TECHNOLOGIES PRIVATE LIMITED</p>
                   <p>Velspace 4th floor , Amritha Towers,</p>
                   <p>Camelot Layout, Botanical Garden Road,</p>
                   <p>Kondapur -500084, Telangana, INDIA</p>
                  </div>
                </div>
                <div class="app-contact">CONTACT INFO : 18003097199</div>
              </div>
              <div class="screen-body-item">
                <form [formGroup]="myForm" #formDirective="ngForm">
                    <div class="app-form">
                        <div class="app-form-group">
                          <input class="app-form-control" placeholder="NAME"  formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required"> Name is required</div>
                            <div *ngIf="f.name.errors.pattern"> Name should consists alphabets only.</div>
                        </div>
                        </div>
                        <div class="app-form-group">
                          <input class="app-form-control" placeholder="EMAIL" formControlName="emailId" [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }">
                          <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
                            <div *ngIf="f.emailId.errors.required">EmailId is required</div>
                            <div *ngIf="f.emailId.errors.email">Email must be a valid email address</div>
                        </div>
                        
                        </div>
                        <div class="app-form-group">
                          <input class="app-form-control" placeholder="CONTACT NO"  formControlName="phoneNo" [ngClass]="{ 'is-invalid': submitted && f.phoneNo.errors }">
                          <div *ngIf="submitted && f.phoneNo.errors" class="invalid-feedback">
                            <div *ngIf="f.phoneNo.errors.required">Contact Number is required</div>
                            <div *ngIf="f.phoneNo.errors.pattern || f.phoneNo.errors.maxlength || f.phoneNo.errors.minlength">Contact number must be valid</div>
                            <!-- <div *ngIf="f.phoneNo.errors.pattern">Contact number must be in format of: <p> 123-456-7890 </p></div> -->
                        </div>
                        </div>
                        <div class="app-form-group message">
                          <textarea name="" id="" cols="6" rows="4" class="app-form-control" placeholder="MESSAGE" formControlName="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                          <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                            <div *ngIf="f.message.errors.required">Message is required</div>
                        </div>
                          <!-- <input  class="app-form-control" placeholder="MESSAGE"> -->
                        </div>
                        <div class="">
                          <input type="checkbox" (change)="toggleVisibility($event)" id="check"> <label for="check" class="ml-2 checkbox-content"><span class="text-white">You agree to the terms of use and acknowledge the</span></label><a (click)="getDisclaimer()" class="text-primary ml-1 privacy">privacy policy</a>
                        </div>
                        <div class="app-form-group buttons mt-2">
                          <!-- <button type="reset" class="app-form-button">CANCEL</button> -->
                          <button type="submit" class="app-form-button" (click)="submit()" >SEND</button>
                        </div>
                      </div>
                    </form>
              </div>
            </div>
          </div>
          
        </div>
      </div>
</div>
  