import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  sliderOne = "../assets/images/slider/home3_2.jpg";
  sliderTwo = "../assets/images/slider/home3_1.jpg";
  latestNewsTitle: string = "JUJUBE";
  newsDetails: string = "JUJUBE is LIVE in Google Playstore";
  

  ramesh = { imagePath: "../../assets/images/ramesh.png", name: "Ramesh", role: "Founder & Sr. Architect" };
  rajesh = { imagePath: "../../assets/images/rajesh.png", name: "Rajesh", role: "Co-Founder & Architect" };
  anusha = { imagePath: "../assets/images/anusha.png", name: "Anusha", role: "QA Engineer"};
  vamshi = { imagePath: "../../assets/images/vamshi.png", name: "Vamshi", role: "Mobile Apps Lead" };
  supriya = { imagePath: "../../assets/images/supriya.png", name: "Supriya M", role: "QA Engineer" };
  manasa = { imagePath: "../../assets/images/eva.jpg", name: "Manasa", role: "Product Development Manager" };
  lakshmiB = { imagePath: "../../assets/images/lakshmiB.png", name: "Bhagya Lakshmi", role: "Product Engineer" };
  // meera = { imagePath: "../../assets/images/meera.jpg", name: "Nagul Meera", role: "Python Developer" };
  // manoj = { imagePath: "../../assets/images/manoj.jpg", name: "Manoj", role: "Python Developer" };
  mohan = { imagePath: "../../assets/images/mohan.png", name: "Mohan", role: "Jujube Field Operations Manager" }
 
  constructor(
    private router: Router,
  ) { }

  [x: string]: any;

  
  vision: string = "Pradiota Technologies envisioned an unique, novel and sustainable platform for businesses to expand beyond their customer reach. Jujube is India’s ONLY & 1st ever e-ecommerce platform empowering common man’s business.";
  mission: string = "Deliver top quality of services to our clients with executing efficiency and integrity";
  objective: string = "Build mutually beneficial relationships with our stakeholders for future and long-term";
  support: string = "Aiming to exceed client expectation consistently by imbibing Teamwork, Professionalism, Personalised Service & Specialisation";

  highlightContent: string = "We believe in the value of relationships. We view every client relationship like a partnership, and truly believe that our success is a result of your success";
  miniContent: string = "We are committed to providing close, personal attention to our clients. We take pride in giving you the assurance that the assistance you receive comes from years of advanced training, technical experience and financial acumen. Our continual investment of time and resources in professional continuing education, advanced computer technology and extensive business relationships is indicative of our commitment to excellence.";

//   goToContactUsPage(){
//     debugger;
//    this.router.navigate(['contact-us']);
// }



  ngOnInit() {
    var header = $('.menu-sticky');
    var win = $(window);

    win.on('scroll', function () {
      var scroll = win.scrollTop();
      if (scroll < 1) {
        header.removeClass("sticky");
      } else {
        header.addClass("sticky");
      }

      $("section").each(function () {
        var elementTop = $(this).offset().top - $('#rs-header').outerHeight();
        if (scroll >= elementTop) {
          $(this).addClass('loaded');
        }
      });

    });

    //window load
    $(window).on('load', function () {
      $("#loading").delay(1500).fadeOut(500);
      $("#loading-center").on('click', function () {
        $("#loading").fadeOut(500);
      })
    })

    //Testimonials Slider
    var sliderfor = $('.slider-for');
    if (sliderfor.length) {
      (<any>$('.slider-for')).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav',
        autoplay: true
      });
    }
    var slidernav = $('.slider-nav');
    if (slidernav.length) {
      $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        centerMode: true,
        focusOnSelect: true
      });
    }

    // collapse hidden  
    var navMain = $(".navbar-collapse");
    navMain.on("click", "a:not([data-toggle])", null, function () {
      navMain.collapse('hide');
    });


    // Slider Custom jQuery
    var nivo_slider = $('#nivoSlider');
    if (nivo_slider.length) {
      $('#nivoSlider').nivoSlider({
        effect: 'random',
        slices: 15,
        boxCols: 8,
        boxRows: 4,
        animSpeed: 950,
        pauseTime: 2500,
        startSlide: 1,
        directionNav: true,
        controlNavThumbs: false,
        pauseOnHover: true,
        manualAdvance: false
      });
    }

    // video 
    if ($('.player').length) {
      $(".player").YTPlayer();
    }


    // image loaded portfolio init
    var gridfilter = $('.grid');
    if (gridfilter.length) {
      $('.grid').imagesLoaded(function () {
        $('.gridFilter').on('click', 'button', function () {
          var filterValue = $(this).attr('data-filter');
          $grid.isotope({
            filter: filterValue
          });
        });
        var $grid = $('.grid').isotope({
          itemSelector: '.grid-item',
          percentPosition: true,
          masonry: {
            columnWidth: '.grid-item',
          }
        });
      });
    }

    // project Filter
    if ($('.gridFilter button').length) {
      var projectfiler = $('.gridFilter button');
      if (projectfiler.length) {
        $('.gridFilter button').on('click', function (event) {
          $(this).siblings('.active').removeClass('active');
          $(this).addClass('active');
          event.preventDefault();
        });
      }
    }

    // magnificPopup init
    var imagepopup = $('.image-popup');
    if (imagepopup.length) {
      $('.image-popup').magnificPopup({
        type: 'image',
        callbacks: {
          beforeOpen: function () {
            this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure animated zoomInDown');
          }
        },
        gallery: {
          enabled: true
        }
      });
    }

    // Get a quote popup
    var popupquote = $('.popup-quote');
    if (popupquote.length) {
      $('.popup-quote').magnificPopup({
        type: 'inline',
        preloader: false,
        focus: '#qname',
        removalDelay: 500,
        callbacks: {
          beforeOpen: function () {
            this.st.mainClass = this.st.el.attr('data-effect');
            if (win.width() < 700) {
              this.st.focus = false;
            } else {
              this.st.focus = '#qname';
            }
          }
        }
      });
    }

    //Videos popup jQuery 
    var popupvideos = $('.popup-videos');
    if (popupvideos.length) {
      $('.popup-videos').magnificPopup({
        disableOn: 10,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
      });
    }

    /*-------------------------------------
    OwlCarousel
    -------------------------------------*/
    $('.rs-carousel').each(function () {
      var owlCarousel = $(this),
        loop = owlCarousel.data('loop'),
        items = owlCarousel.data('items'),
        margin = owlCarousel.data('margin'),
        stagePadding = owlCarousel.data('stage-padding'),
        autoplay = owlCarousel.data('autoplay'),
        autoplayTimeout = owlCarousel.data('autoplay-timeout'),
        smartSpeed = owlCarousel.data('smart-speed'),
        dots = owlCarousel.data('dots'),
        nav = owlCarousel.data('nav'),
        navSpeed = owlCarousel.data('nav-speed'),
        xsDevice = owlCarousel.data('mobile-device'),
        xsDeviceNav = owlCarousel.data('mobile-device-nav'),
        xsDeviceDots = owlCarousel.data('mobile-device-dots'),
        smDevice = owlCarousel.data('ipad-device'),
        smDeviceNav = owlCarousel.data('ipad-device-nav'),
        smDeviceDots = owlCarousel.data('ipad-device-dots'),
        smDevice2 = owlCarousel.data('ipad-device2'),
        smDeviceNav2 = owlCarousel.data('ipad-device-nav2'),
        smDeviceDots2 = owlCarousel.data('ipad-device-dots2'),
        mdDevice = owlCarousel.data('md-device'),
        mdDeviceNav = owlCarousel.data('md-device-nav'),
        mdDeviceDots = owlCarousel.data('md-device-dots');
      owlCarousel.owlCarousel({
        loop: (loop ? true : false),
        items: (items ? items : 4),
        lazyLoad: true,
        margin: (margin ? margin : 0),
        //stagePadding: (stagePadding ? stagePadding : 0),
        autoplay: (autoplay ? true : false),
        autoplayTimeout: (autoplayTimeout ? autoplayTimeout : 1000),
        smartSpeed: (smartSpeed ? smartSpeed : 250),
        dots: (dots ? true : false),
        nav: (nav ? true : false),
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        navSpeed: (navSpeed ? true : false),
        responsiveClass: true,
        responsive: {
          0: {
            items: (xsDevice ? xsDevice : 1),
            nav: (xsDeviceNav ? true : false),
            dots: (xsDeviceDots ? true : false)
          },
          576: {
            items: (smDevice2 ? smDevice : 2),
            nav: (smDeviceNav2 ? true : false),
            dots: (smDeviceDots2 ? true : false)
          },
          768: {
            items: (smDevice ? smDevice : 3),
            nav: (smDeviceNav ? true : false),
            dots: (smDeviceDots ? true : false)
          },
          992: {
            items: (mdDevice ? mdDevice : 4),
            nav: (mdDeviceNav ? true : false),
            dots: (mdDeviceDots ? true : false)
          }
        }
      });

    });




    // Counter Up
    if ($('.rs-counter').length) {
      $('.rs-counter').counterUp({
        delay: 20,
        time: 1500
      });
    }

    // scrollTop init	
    var totop = $('#scrollUp');
    win.on('scroll', function () {
      if (win.scrollTop() > 150) {
        totop.fadeIn();
      } else {
        totop.fadeOut();
      }
    });
    totop.on('click', function () {
      $("html,body").animate({
        scrollTop: 0
      }, 500)
    });

    //canvas menu
    var navexpander = $('#nav-expander');
    if (navexpander.length) {
      $('#nav-expander').on('click', function (e) {
        e.preventDefault();
        $('body').toggleClass('nav-expanded');
      });
    }
    var navclose = $('#nav-close');
    if (navclose.length) {
      $('#nav-close').on('click', function (e) {
        e.preventDefault();
        $('body').removeClass('nav-expanded');
      });
    }

    /*----------------------------
    single-productjs active
    ------------------------------ */
    var singleproductimage = $('.single-product-image');
    if (singleproductimage.length) {
      $('.single-product-image').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.single-product-nav'
      });
    }

    var singleproductnav = $('.single-product-nav');
    if (singleproductnav.length) {
      $('.single-product-nav').slick({
        slidesToShow: 3,
        asNavFor: '.single-product-image',
        dots: false,
        focusOnSelect: true,
        centerMode: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 591,
            settings: {
              slidesToShow: 2
            }
          }
        ]
      });
    }

    $('.video').parent().click(function () {
      if($(this).children(".video").get(0).paused){  
          $(this).children(".video").get(0).play();   
          $(this).children(".playpause").fadeOut();
        }
      else{ 
          $(this).children(".video").get(0).click();
          $(this).children(".playpause").fadeIn();
        }
    });
  }

}
