<!-- Footer Start -->
<footer id="rs-footer" class="rs-footer">
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-md-12 col-sm-12 footer-widget-one mb-md-50">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d30447.414870503057!2d78.3478905!3d17.4632145!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x77e04ddea16d5a83!2sPRADIOTA%20IT%20SOLUTIONS%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1591916392351!5m2!1sen!2sin"
                        width="450" height="300" frameborder="0" style="border:0;" allowfullscreen=""
                        aria-hidden="false" tabindex="0"></iframe>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12 footer-widget-three mb-md-50">
                    <div class="footer-menu">
                        <h5 class="footer-title">Contact Us</h5>
                        <ul class="footer-address">
                            <li><i class="fa fa-map-marker"></i><span>
                                <p>PRADIOTA IT SOLUTIONS PRIVATE LIMITED</p><br>
                                <p>VELSPACE 4th FLOOR</p><br>
                                <p> AMRITHA TOWERS CAMELOT LAYOUT</p><br>
                                <p>BOTANICAL GARDEN ROAD</p><br>
                                <p>KONDAPUR -500084, TELANGANA</p></span></li>
                            <!-- <li><i class="fa fa-phone"></i><a href="#"><span>+3453-909-6565</span></a></li>
                            <li><i class="fa fa-envelope-o"></i><span><a href="#">info@yourdmain.com </a><a
                                        href="#">www.yourcompanyname.com</a></span></li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12 footer-widget-one">
                    <div class="about-widget">
                        <h5 class="footer-title">Connect with us</h5>
                        <ul class="social-links">
                            <li><a href="https://www.facebook.com/pradiotatechnologies"><i class="fa fa-facebook"></i></a></li>
                            <li><a href="https://twitter.com/pradiotatech"><i class="fa fa-twitter"></i></a></li>
                            <li><a href="https://www.instagram.com/pradiotait/"><i class="fa fa-instagram"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/pradiota-it-solutions/"><i class="fa fa-linkedin"></i></a></li>
                        </ul>
                        <div class="footer-menu mt-3">
                            <ul class="footer-address">
                                <li>
                                    <i class="fa fa-phone"></i>
                                    <a href="tel:+18003097199"><span class="contact">18003097199</span></a>
                                </li>
                                <li>
                                    <i class="fa fa-envelope-o"></i>
                                    <!-- <span class="contact"><a  href="mailto:support@pradiota.com">support@pradiota.com </a><br></span> -->
                                    <a href="mailto:support@pradiota.com"><span class="contact">support@pradiota.com</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom">
        <div class="container">
            <div class="copyright text-center">
                <p>&copy; 2020 All Rights Reserved</p>
            </div>
        </div>
    </div>
</footer>
<!-- Footer End -->

<!-- start scrollUp  -->
<div id="scrollUp">
    <i class="fa fa-angle-up"></i>
</div>
<!-- End scrollUp  -->

<!-- <a href="https://web.whatsapp.com/send?phone=+919533673777" class="float" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
</a> -->